import React, { FC, useContext } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { MultiColumnRichTextProps } from "../generated-types";
import { themable } from "~/foundation/Theme";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { RichTextStyling } from "~/foundation/Components/RichText/styles";
import { GenericHeader } from "~/foundation/Components/GenericHeader";

const MultiColumnRichText: FC<MultiColumnRichTextProps> = ({ fields }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const richTexts = [
		fields?.column1?.value,
		fields?.column2?.value,
		fields?.column3?.value,
	].filter(Boolean);
	const columnCount = sitecoreContext.pageEditing ? 3 : richTexts.length;

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper py={["0", null]}>
				<Flex
					flexDirection="row"
					gap={["7", null, "3rem"]}
					flexWrap="wrap"
					justifyContent={["none", null, "center"]}
					sx={{
						"> div": {
							flexBasis: ["100%", null, columnCount > 1 ? `calc(50% - 1.5rem)` : "60%", columnCount > 1 ? `calc(100% / ${columnCount} - 2rem)` : "60%"],
						}
					}}
				>
					{(fields?.column1?.value || sitecoreContext.pageEditing) &&
						<Box
							{...RichTextStyling(theme, sitecoreContext.custom.settings.isRtl)}>
							<sc.RichText field={fields?.column1} enableWordExplainer />
						</Box>
					}
					{(fields?.column2?.value || sitecoreContext.pageEditing) &&
						<Box
							{...RichTextStyling(theme, sitecoreContext.custom.settings.isRtl)}>
							<sc.RichText field={fields?.column2} enableWordExplainer />
						</Box>
					}
					{(fields?.column3?.value || sitecoreContext.pageEditing) &&
						<Box
							{...RichTextStyling(theme, sitecoreContext.custom.settings.isRtl)}>
							<sc.RichText field={fields?.column3} enableWordExplainer />
						</Box>
					}
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(MultiColumnRichText);